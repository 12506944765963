import logo from './logo.svg';
import './App.css';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {Link} from "react-router-dom";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div style={{ position: 'absolute', top: '82px', left: '50px'}}>
          <img src={logo} className="App-logo" alt="logo" />
        </div>
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
          <h2>Ladal - Lär dig att läsa</h2>
        </div>
        <div style={{ maxWidth: '30%', margin: '0 auto' }}>
          <Carousel style={{ maxWidth: '20%', maxHeight: '100px' }}>
            <div>
              <img src={process.env.PUBLIC_URL + '/img/start.png'} alt="" width={100} />
            </div>
            <div>
              <img src={process.env.PUBLIC_URL + '/img/section.png'} alt="" width={100} />
            </div>
            <div>
              <img src={process.env.PUBLIC_URL + '/img/level-word.png'} alt="" width={100} />
            </div>
            <div>
              <img src={process.env.PUBLIC_URL + '/img/level-finish.png'} alt="" width={100} />
            </div>
            <div>
              <img src={process.env.PUBLIC_URL + '/img/section-finished.png'} alt="" width={100} />
            </div>
          </Carousel>
        </div>
      </header>
      <div className="footer">
        <p>© 2023 Abrov1nc No.1337 AB. All rights reserved.</p>
        <Link to="/policy">
          Privacy Policy
        </Link>
      </div>

    </div>
  );
}

export default App;
