import React from 'react';
import './App.css';

const PolicyPage = () => {
  return (
      <header className="App-header">
      <div style={{ maxWidth: '800px', margin: '0 auto', padding: '20px' }}>
        <h1 style={{ textAlign: 'center' }}>Sekretesspolicy för Ladal-appen</h1>
        <p>
          Denna sekretesspolicy beskriver vår hantering av användardata när du använder Ladal-appen för att lära barn att läsa.
        </p>
        <h2>Insamlad information:</h2>
        <p>
          Vi samlar inte in någon personlig information när du använder Ladal-appen. Din integritet är vår högsta prioritet, och vi strävar efter att skapa en säker och privat inlärningsmiljö utan behov av användarkonton eller personlig data.
        </p>
        <h2>Användning av information:</h2>
        <p>
          Eftersom ingen personlig information samlas in används ingen sådan information för att anpassa användarupplevelsen. Appens funktionalitet fokuserar helt på lärande och interaktion utan att behöva lagra användardata.
        </p>
        <h2>Delning av information:</h2>
        <p>
          Eftersom ingen personlig information samlas in delas ingen sådan information heller med tredje parter.
        </p>
        <h2>Barns integritet:</h2>
        <p>
          Ladal-appen är utformad för att vara säker och lämplig för barn. Vi samlar inte medvetet in personlig information från barn under 13 år.
        </p>
        <h2>Säkerhet:</h2>
        <p>
          Vi upprätthåller säkerhetsåtgärder för att skydda användardata som inte samlas in från förlust, missbruk och obehörig åtkomst.
        </p>
        <h2>Ändringar i sekretesspolicyn:</h2>
        <p>
          Eventuella framtida ändringar i vår praxis kommer att uppdateras i denna sekretesspolicy.
        </p>
        <p>
          Genom att använda Ladal-appen samtycker du till denna sekretesspolicy. Tack för att du väljer Ladal för att stödja barns lärande!
        </p>
      </div>
      </header>
  );
};

export default PolicyPage;