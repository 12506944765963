import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './App';
import PolicyPage from './PolicyPage';

const AppRoute = () => {
  return (
      <Router>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/policy" element={<PolicyPage />} />
        </Routes>
      </Router>
  );
};

export default AppRoute;
